import type { Payload } from "@local/payload-client/src/types";
import styles from "./styles.module.css";
import Image from "@/components/Image";
export const DoctorQuoteBlock = ({
  block
}: {
  block: Payload.DoctorQuoteBlock;
}) => {
  return <section id={block.id} className="mb-10" data-sentry-component="DoctorQuoteBlock" data-sentry-source-file="index.tsx">
      <div className={`px-4 pt-16 pb-24 lg:pb-20 text-center relative ${block.dark ? "bg-[#1B203B]" : "bg-[#AFE7F0]"}`}>
        <div className={`w-4/5 md:w-1/2 mx-auto overflow-visible md:relative text-2xl md:text-xl ${block.dark ? styles.DoctorQuote_Dark : styles.DoctorQuote} ${block.dark ? "text-white" : "text-[#05225F]"}`}>
          {block.text ?? "Foot pain accessories can play a crucial role in promoting overall foot health and preventing certain ailments. They help correct alignment issues, reduce pressure on specific areas, absorb shock, and provide extra support where needed."}
        </div>
      </div>
      <div className="flex flex-col items-center w-full -mt-[50px] z-10 relative">
        <Image src="/images/drmauriciogarcia.png" alt="Dr. Mauricio Garcia" width={99} height={99} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        <h3>
          <b>DR. MAURICIO GARCIA</b>
        </h3>
        <p>Specialist Orthopedic Surgeon</p>
      </div>
    </section>;
};
export default DoctorQuoteBlock;